import {
  Autocomplete,
  Button,
  Chip,
  Divider,
  Stack,
  TextField,
} from "@mui/material";
import React from "react";
import TextFieldNew from "../../components/TextFieldNew";
import { GlobalContext } from "../../context/GlobalContext";

const DeployApp = () => {
  const [users, setUsers] = React.useState([]);
  const [plans, setPlans] = React.useState([]);
  const { hitAxios } = React.useContext(GlobalContext);
  const [state, setState] = React.useState({
    user: {},
    plan: {},
  });

  async function getPlans(params) {
    const res = await hitAxios({
      path: "/api/web/get_plans",
      post: false,
      admin: false,
    });
    if (res.data.success) {
      setPlans(res.data.data);
    }
  }

  async function getUsers(params) {
    const res = await hitAxios({
      path: "/api/admin/get_users",
      post: false,
      admin: true,
      token_user: localStorage.getItem(process.env.REACT_APP_TOKEN + "_hamid"),
    });
    if (res.data.success) {
      setUsers(res.data.data);
    }
  }

  async function allotPlan(uid, plan) {
    const res = await hitAxios({
      path: "/api/admin/allot_plan",
      post: true,
      admin: true,
      obj: {
        uid,
        plan,
      },
    });
  }

  React.useEffect(() => {
    getUsers();
    getPlans();
  }, []);

  return (
    <div>
      <Stack direction={"column"} spacing={3}>
        <Autocomplete
          size="small"
          sx={{ "& fieldset": { borderRadius: 3 } }}
          disablePortal
          options={users.map((x) => {
            return {
              ...x,
              label: `${x.email} || ${x.mobile} || ${x.uid}`,
            };
          })}
          onChange={(e, val) => {
            setState({ ...state, user: val });
          }}
          renderInput={(params) => (
            <TextField size="small" {...params} label="Select User" />
          )}
        />

        <Stack direction={"row"} spacing={2} sx={{ overflowX: "auto" }}>
          {plans.map((x) => {
            return (
              <Chip
                onClick={() => setState({ ...state, plan: x })}
                variant={x.id === state?.plan?.id ? "filled" : "outlined"}
                label={x.title}
                size="large"
              />
            );
          })}
        </Stack>

        <Button
          disabled={
            Object.keys(state.user).length === 0 ||
            Object.keys(state.plan).length === 0
          }
          onClick={() => {
            allotPlan(state.user.uid, state.plan.id);
          }}
          variant="contained"
          size="large"
        >
          Add Plan
        </Button>

        <Divider />
      </Stack>
    </div>
  );
};

export default DeployApp;
