import {
  BrowserUpdatedOutlined,
  CloudSync,
  Close,
  OpenInNew,
} from "@mui/icons-material";
import {
  Button,
  Divider,
  IconButton,
  Stack,
  Tooltip,
  Dialog,
  Box,
  Typography,
  Alert,
} from "@mui/material";
import { GlobalContext } from "../../../../../context/GlobalContext";
import { LoadingButton } from "@mui/lab";
import TextFieldNew from "../../../../../components/TextFieldNew";
import React from "react";

const ActionButtonWhatsHam = ({ data, i, hitAxios, buttonDeploy }) => {
  const CON = React.useContext(GlobalContext);
  const [state, setState] = React.useState({
    dialog: false,
  });

  async function hitUpdate() {
    const res = await hitAxios({
      path: "/api/whatsham/update_app",
      post: true,
      admin: false,
      obj: { ...state, id: i?.id },
    });
    if (res.data.success) {
      alert("DONT FORGET TO RESTART YOUR NODEJS APP");
    }
  }

  async function hitInstall() {
    const res = await hitAxios({
      path: "/api/whatsham/install_app",
      post: true,
      admin: false,
      obj: { id: i?.id },
    });
  }

  return (
    <div>
      <Dialog
        fullWidth
        PaperProps={{
          style: {
            borderRadius: 10,
            backgroundColor: "rgba(255, 255, 255, 0)",
            backdropFilter: "blur(4px)",
          },
        }}
        onClose={() => setState({ ...state, dialog: false })}
        open={state.dialog}
      >
        <Box p={2}>
          <Stack direction={"column"} spacing={2}>
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Typography fontWeight={"bold"}>{data.updateApp}</Typography>
              <IconButton onClick={() => setState({ ...state, dialog: false })}>
                <Close />
              </IconButton>
            </Stack>

            <Divider />

            <Box bgcolor={"action.hover"} p={2} borderRadius={2}>
              <Stack direction={"column"} spacing={2}>
                <Typography color={"orange"}>
                  ⚠️ {data.pleaseDONotForget}
                </Typography>

                <Stack direction={"column"} spacing={1}>
                  <Typography fontWeight={300} variant="body2">
                    👉🏻 {data.frst}
                  </Typography>

                  <Typography fontWeight={300} variant="body2">
                    👉🏻 {data.second}
                  </Typography>

                  <Typography fontWeight={300} variant="body2">
                    👉🏻 {data.third}
                  </Typography>

                  <Typography fontWeight={300} variant="body2">
                    👉🏻 {data.forth}
                  </Typography>
                </Stack>
              </Stack>
            </Box>

            <TextFieldNew
              onChange={(e) => setState({ ...state, password: e.target.value })}
              helperText={data.toMakeSecureRequest}
              label={data.enterWhatsCRmAdmin}
            />

            <LoadingButton
              loading={CON.data.loading}
              onClick={hitUpdate}
              sx={{ boxShadow: 0, borderRadius: 2 }}
              variant="contained"
            >
              {data.startUpdating}
            </LoadingButton>

            {CON.data.snack && (
              <Alert severity={CON.data.snack_success ? "success" : "error"}>
                {CON.data.snack_msg}
              </Alert>
            )}
          </Stack>
        </Box>
      </Dialog>

      <Stack direction={"row"} justifyContent={"flex-end"} spacing={2}>
        {buttonDeploy}
        <Tooltip title={data.openAppDoc}>
          <IconButton
            onClick={() => {
              window.open("https://doc-whatsham.oneoftheprojects.com/");
            }}
            size="small"
          >
            <OpenInNew />
          </IconButton>
        </Tooltip>

        <Button
          onClick={() => setState({ ...state, dialog: true })}
          size="small"
          startIcon={<CloudSync />}
          sx={{
            boxShadow: 0,
            borderRadius: 2,
          }}
          variant="contained"
          color="info"
        >
          {data.updateApp}
        </Button>

        <Button
          onClick={hitInstall}
          size="small"
          startIcon={<BrowserUpdatedOutlined />}
          sx={{
            boxShadow: 0,
            borderRadius: 2,
          }}
          variant="contained"
          color="success"
        >
          {data.installApp}
        </Button>
      </Stack>
    </div>
  );
};

export default ActionButtonWhatsHam;
