import React from "react";
import PropTypes from "prop-types";
import { Box, Button, Tab, Tabs } from "@mui/material";
import AddProduct from "./components/addProduct/AddProduct";
import Support from "./components/suport/Support";
import CommunityComp from "./components/community/CommunityComp";
import Profile from "./components/profile/Profile";
import AutoDeploy from "./components/autoDeploy/AutoDeploy";
import second from "../components/button.css";

function CustomTabPanel({ children, value, index, ...other }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3, animation: "fadeIn 0.3s ease-in-out" }}>
          {children}
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
}

const TabsComp = ({ data, value, setValue, handleChange }) => {
  return (
    <Box sx={{ bgcolor: "action.hover", borderRadius: 4 }}>
      {/* Tab Header */}
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          px: 2,
          backgroundColor: "action.hover",
          borderRadius: 4,
        }}
      >
        <Tabs
          variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="navigation tabs"
          // TabIndicatorProps={{
          //   style: { backgroundColor: "#FF4081", height: "4px" },
          // }}
        >
          <Tab
            label={data.myPurchase}
            {...a11yProps(0)}
            sx={{
              textTransform: "capitalize",
              fontWeight: "bold",
              "&:hover": { color: "primary.main" },
            }}
          />
          <Tab
            label={data.support}
            {...a11yProps(1)}
            sx={{
              textTransform: "capitalize",
              fontWeight: "bold",
              "&:hover": { color: "primary.main" },
            }}
          />
          <Tab
            label={data.community}
            {...a11yProps(2)}
            sx={{
              textTransform: "capitalize",
              fontWeight: "bold",
              "&:hover": { color: "primary.main" },
            }}
          />
          <Tab
            label={data.changePass}
            {...a11yProps(3)}
            sx={{
              textTransform: "capitalize",
              fontWeight: "bold",
              "&:hover": { color: "primary.main" },
            }}
          />
          <Tab
            className="gradient-border-animation"
            label={data.autoDeploy}
            {...a11yProps(4)}
            sx={{
              textTransform: "capitalize",
              fontWeight: "bold",
              "&:hover": { color: "primary.main" },
              color: "black",
            }}
          />
        </Tabs>
      </Box>

      {/* Tab Content */}
      <CustomTabPanel value={value} index={0}>
        <AddProduct setValue={setValue} data={data} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <Support data={data} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <CommunityComp data={data} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
        <Profile data={data} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={4}>
        <AutoDeploy data={data} />
      </CustomTabPanel>
    </Box>
  );
};

TabsComp.propTypes = {
  data: PropTypes.object.isRequired,
  value: PropTypes.number.isRequired,
  setValue: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default TabsComp;
