import { Box, Divider } from "@mui/material";
import React from "react";
import { GlobalContext } from "../../../../context/GlobalContext";
import OneApp from "./OneApp";

const MyApps = ({ state, setState, data }) => {
  const { hitAxios } = React.useContext(GlobalContext);
  const [apps, setApps] = React.useState([]);

  async function getApps(params) {
    const res = await hitAxios({
      path: "/api/user/get_my_deployed",
      post: false,
      admin: false,
      token_user: localStorage.getItem(process.env.REACT_APP_TOKEN + "_user"),
    });
    if (res.data.success) {
      setApps(res.data.data);
    }
  }

  React.useEffect(() => {
    getApps();
  }, []);
  return (
    <div>
      <Box mt={2} mb={2}>
        <Divider />
      </Box>

      {apps.map((i, key) => {
        return <OneApp data={data} i={i} key={key} />;
      })}
    </div>
  );
};

export default MyApps;
