import React from "react";
import { Route, Switch } from "react-router-dom";
import {
  Alert,
  Snackbar,
  Box,
  LinearProgress,
  Backdrop,
  CircularProgress,
  CardMedia,
  Typography,
} from "@mui/material";
import { GlobalContext } from "./context/GlobalContext";
import PublicRoute from "./routing/PublicRoute";
import AdminRoute from "./routing/AdminRoute";
import UserRoute from "./routing/UserRoute";
import { TranslateContext } from "./context/TranslateContext";
import axios from "axios";
import FrontEnd from "./frontend/FrontEnd";
import UserLogin from "./user/UserLogin";
import UserDash from "./user/UserDash";
import AdminLogin from "./admin/AdminLogin";
import AdminDashboard from "./admin/dashboard/AdminDashboard";

const HomeMainPage = () => {
  const GLOBAL_CONTEXT = React.useContext(GlobalContext);
  const LANGUAGE = React.useContext(TranslateContext);

  React.useEffect(() => {
    const code = localStorage.getItem("language");
    // const localcode = local.slice(0, local.indexOf('.'))
    if (code) {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/api/web/get-one-translation?code=${code}`
        )
        .then((res) => {
          if (res.data.notfound) {
            localStorage.removeItem("language");
            alert("Maybe translate file issue.");
            window.location.reload();
            return;
          }
          LANGUAGE.setData({ ...res.data.data });
        })
        .catch((err) => console.log(err));
    } else {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/api/web/get-all-translation-name`
        )
        .then((lan) => {
          localStorage.setItem(
            "language",
            lan.data.data[0]?.replace(".json", "")
          );
          axios
            .get(
              `${
                process.env.REACT_APP_BASE_URL
              }/api/web/get-one-translation?code=${lan.data.data[0]?.replace(
                ".json",
                ""
              )}`
            )
            .then((res) => {
              LANGUAGE.setData({ ...res.data.data });
            })
            .catch((err) => console.log(err));
        });
    }
  }, []);

  return (
    <Box
      sx={{ position: "relative" }}
      color="text.primary"
      minHeight={"100vh"}
      bgcolor="background.default"
    >
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: 1400,
          backdropFilter: "blur(4px)",
        }}
        // sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1, backdropFilter: "blur(4px)" }}
        open={GLOBAL_CONTEXT.data.loading}
      >
        <CircularProgress />
      </Backdrop>

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={GLOBAL_CONTEXT.data.snack}
        autoHideDuration={4000}
      >
        <Alert
          severity={GLOBAL_CONTEXT.data.snack_success ? "success" : "error"}
        >
          {GLOBAL_CONTEXT.data.snack_msg}
        </Alert>
      </Snackbar>

      <div
        style={{
          opacity: GLOBAL_CONTEXT.data.loading ? 0.25 : 1,
          pointerEvents: GLOBAL_CONTEXT.data.loading ? "none" : "initial",
        }}
      >
        <Switch>
          <PublicRoute path="/" exact component={FrontEnd} />
          <PublicRoute path="/user/login" exact component={UserLogin} />
          <UserRoute path="/user" exact component={UserDash} />
          <PublicRoute path="/hamid/login" exact component={AdminLogin} />
          <AdminRoute path="/hamid" exact component={AdminDashboard} />
        </Switch>
      </div>
    </Box>
  );
};

export default HomeMainPage;
