import { Close } from "@mui/icons-material";
import {
  Box,
  Dialog,
  Divider,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";

const DialogBox = ({ handleDialog, open, title, children, icon }) => {
  return (
    <div>
      <Dialog
        PaperProps={{
          style: {
            borderRadius: 10,
          },
        }}
        fullWidth
        open={open}
        onClose={handleDialog}
      >
        <Box bgcolor={"action.hover"} p={2}>
          <Stack
            direction={"row"}
            spacing={2}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Stack direction={"row"} spacing={1} alignItems={"center"}>
              {icon}
              <Typography variant="h6">{title}</Typography>
            </Stack>
            <IconButton onClick={handleDialog}>
              <Close />
            </IconButton>
          </Stack>
        </Box>
        <Divider />

        <Box p={2}>{children}</Box>
      </Dialog>
    </div>
  );
};

export default DialogBox;
