import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  Container,
  Grid,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import Products from "../products/Products";
import GenerateLicense from "../generate-license/GenerateLicense";
import Dash from "./components/Dash";
import DeployApp from "../deployApp/DeployApp";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const AdminDashboard = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Container maxWidth="lg">
      <Grid container minHeight={"100vh"} justifyContent={"center"}>
        <Grid xs={12} sm={12} lg={12} item>
          <Stack direction={"column"} spacing={2}>
            <Box pt={2}>
              <Stack alignItems={"flex-end"}>
                <Typography>Hey Boss!</Typography>
              </Stack>
            </Box>
            <Box sx={{ width: "100%" }}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                >
                  <Tab label="Dashboard" {...a11yProps(0)} />
                  <Tab label="Products" {...a11yProps(1)} />
                  <Tab label="Generate License" {...a11yProps(2)} />
                  <Tab label="Give Plan" {...a11yProps(3)} />
                </Tabs>
              </Box>
              <CustomTabPanel value={value} index={0}>
                <Dash />
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1}>
                <Products />
              </CustomTabPanel>
              <CustomTabPanel value={value} index={2}>
                <GenerateLicense />
              </CustomTabPanel>
              <CustomTabPanel value={value} index={3}>
                <DeployApp />
              </CustomTabPanel>
            </Box>
          </Stack>
        </Grid>
      </Grid>
    </Container>
  );
};

export default AdminDashboard;
