import {
  Box,
  Button,
  CardMedia,
  Container,
  Grid,
  IconButton,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import TextFieldNew from "../components/TextFieldNew";
import { TranslateContext } from "../context/TranslateContext";
import { GlobalContext } from "../context/GlobalContext";
import { Translate } from "@mui/icons-material";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const UserLogin = () => {
  const { data } = React.useContext(TranslateContext);
  const history = useHistory();
  const { hitAxios } = React.useContext(GlobalContext);
  const [state, setState] = React.useState({
    isLogin: true,
  });

  async function loginUser() {
    const res = await hitAxios({
      path: "/api/user/login_user",
      post: true,
      admin: false,
      obj: state,
    });
    if (res.data.success) {
      localStorage.setItem(
        process.env.REACT_APP_TOKEN + "_user",
        res.data.token
      );
      history.push("/user");
      return;
    }
  }

  async function handleSignup() {
    const res = await hitAxios({
      path: "/api/user/signup",
      post: true,
      admin: false,
      obj: state,
    });
    if (res.data.success) {
      setState({ ...state, isLogin: !state.isLogin });
    }
  }

  return (
    <div>
      {/* <Box p={2} >
                <Stack direction={'row'} justifyContent={'flex-end'}>
                    <Tooltip title={data.changeLang} >
                        <IconButton>
                            <Translate />
                        </IconButton>
                    </Tooltip>
                </Stack>
            </Box> */}
      <Grid
        container
        minHeight={"90vh"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Grid xs={12} lg={3} sm={6} item>
          <Container maxWidth="lg">
            <Stack direction={"column"} spacing={2}>
              <Stack alignItems={"center"}>
                <CardMedia
                  component={"img"}
                  src="/assets/logo.png"
                  sx={{
                    height: 100,
                    width: 100,
                    borderRadius: 4,
                    boxShadow: 4,
                  }}
                />
              </Stack>

              <Typography align="center">{data.helloFromTheDev}</Typography>

              {state.isLogin ? (
                <>
                  <TextFieldNew
                    value={state.email}
                    onChange={(e) =>
                      setState({ ...state, email: e.target.value })
                    }
                    label={data.email}
                  />
                  <TextFieldNew
                    value={state.password}
                    onChange={(e) =>
                      setState({ ...state, password: e.target.value })
                    }
                    label={data.password}
                  />
                  <Button
                    onClick={loginUser}
                    variant="contained"
                    sx={{ borderRadius: 2, boxShadow: 0 }}
                  >
                    {data.submit}
                  </Button>
                </>
              ) : (
                <>
                  <TextFieldNew
                    value={state.name}
                    onChange={(e) =>
                      setState({ ...state, name: e.target.value })
                    }
                    label={data.name}
                  />
                  <TextFieldNew
                    value={state.mobile}
                    onChange={(e) =>
                      setState({ ...state, mobile: e.target.value })
                    }
                    type="number"
                    startIcon={<Typography>+</Typography>}
                    label={data.mobile}
                  />
                  <TextFieldNew
                    value={state.email}
                    onChange={(e) =>
                      setState({ ...state, email: e.target.value })
                    }
                    type="email"
                    label={data.email}
                  />
                  <TextFieldNew
                    value={state.password}
                    onChange={(e) =>
                      setState({ ...state, password: e.target.value })
                    }
                    label={data.password}
                  />
                  <Typography variant="body2" color={"orange"}>
                    🔔 {data.signUpNote}
                  </Typography>
                  <Button
                    onClick={handleSignup}
                    variant="contained"
                    sx={{ borderRadius: 2, boxShadow: 0 }}
                  >
                    {data.submit}
                  </Button>
                </>
              )}

              <Stack spacing={1} direction={"column"} alignItems={"flex-end"}>
                <Typography
                  onClick={() =>
                    setState({ ...state, isLogin: !state.isLogin })
                  }
                  sx={{ cursor: "pointer" }}
                  variant="body2"
                >
                  {state.isLogin ? data.signup : data.login}
                </Typography>

                {/* <Typography sx={{ cursor: 'pointer' }} variant='body2'>
                                    {data.forgotPassw}
                                </Typography> */}
              </Stack>
            </Stack>
          </Container>
        </Grid>
      </Grid>
    </div>
  );
};

export default UserLogin;
