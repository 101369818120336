import React from "react";
import {
  Box,
  Button,
  Chip,
  IconButton,
  Stack,
  Typography,
  Divider,
  Alert,
} from "@mui/material";
import {
  Language,
  Domain,
  Sync,
  Event,
  AssignmentTurnedIn,
  OpenInNew,
  Settings,
  RocketLaunch,
  BuildCircle,
  RestartAlt,
  Support,
  SupportAgent,
} from "@mui/icons-material";
import moment from "moment";
import SetupApp from "./oneapp/SetupApp";
import { GlobalContext } from "../../../../context/GlobalContext";

const OneApp = ({ i, data }) => {
  const expiryDate = moment.unix(i.expiry / 1000).format("YYYY-MM-DD");
  const createdDate = moment(i.createdAt).format("YYYY-MM-DD");
  const { data: contextData } = React.useContext(GlobalContext);

  function returnLine({ title, icon, des, chipDes, link }) {
    return (
      <Stack direction="row" alignItems="center" spacing={2}>
        <Stack direction="row" alignItems="center" spacing={1}>
          {icon}
          <Typography
            variant="body1"
            sx={{ fontWeight: "bold", color: "text.primary" }}
          >
            {title}
          </Typography>
        </Stack>
        {chipDes ? (
          <Chip
            size="small"
            color="info"
            label={chipDes}
            sx={{ fontWeight: "bold" }}
          />
        ) : (
          <Typography variant="body2" color="text.secondary">
            {des}
          </Typography>
        )}
        {link && i?.domain && (
          <IconButton
            size="small"
            onClick={() => window.open(`http://${i.domain}`, "_blank")}
            color="primary"
          >
            <OpenInNew fontSize="small" />
          </IconButton>
        )}
      </Stack>
    );
  }

  return (
    <Box
      bgcolor="background.paper"
      borderRadius={3}
      p={3}
      sx={
        {
          // border: "1px solid",
          // borderColor: "divider",
          // transition: "transform 0.3s ease, box-shadow 0.3s ease",
          // "&:hover": {
          //   transform: "scale(1.02)",
          //   boxShadow: "0 6px 16px rgba(0, 0, 0, 0.2)",
          // },
        }
      }
    >
      {/* Header Section */}
      <Stack spacing={2} pb={2}>
        <Typography
          variant="h6"
          sx={{ fontWeight: "bold", color: "primary.main" }}
        >
          {i.app_name || data.na}
        </Typography>
        <Divider />
      </Stack>

      {/* Details Section */}
      <Stack spacing={2}>
        {returnLine({
          title: data.domain || "Domain",
          icon: <Domain color="secondary" fontSize="small" />,
          des: i.domain || data.na,
          link: true,
        })}

        {returnLine({
          title: data.status || "Status",
          icon: <Sync color="info" fontSize="small" />,
          chipDes: i.status,
        })}

        {returnLine({
          title: data.expiry || "Expiry",
          icon: <Event color="warning" fontSize="small" />,
          des: expiryDate,
        })}

        {returnLine({
          title: data.created || "Created",
          icon: <AssignmentTurnedIn color="success" fontSize="small" />,
          des: createdDate,
        })}
      </Stack>

      {/* Footer Buttons */}
      <Stack spacing={2} direction="row" justifyContent="flex-end" mt={3}>
        <SetupApp contextData={contextData} i={i} data={data} />
        <Button startIcon={<RestartAlt />} variant="contained" color="error">
          {data.resetApp}
        </Button>
      </Stack>

      <Box mt={2}>
        <Alert
          severity="warning"
          icon={<Language />}
          sx={{
            // bgcolor: "action.hover",
            borderRadius: 2,
          }}
        >
          <Typography variant="body2" color="text.primary">
            {data.ipPOint}
            <Typography
              component="span"
              variant="body1"
              color="primary.main"
              fontWeight="bold"
              sx={{ cursor: "pointer", ml: 1 }}
              onClick={() =>
                window.navigator?.clipboard
                  ?.writeText(data.ipAddress)
                  .then(() => alert(data.ipCopied))
              }
            >
              {data.ipAddress}
            </Typography>
          </Typography>
        </Alert>
      </Box>

      <Box mt={2}>
        <Stack alignItems={"flex-end"}>
          <Button
            onClick={() =>
              window.open(
                `https://wa.me/918430088300?text=Hi%20Need%20Help%20in%20${i.app_name}`
              )
            }
            startIcon={<SupportAgent />}
            variant="contained"
            color="info"
          >
            {data.contactSupport}
          </Button>
        </Stack>
      </Box>
    </Box>
  );
};

export default OneApp;
