import { Close, CloudSync } from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  Container,
  Dialog,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import PriceComp from "./PriceComp";
import { GlobalContext } from "../../../../context/GlobalContext";

const DialogNew = ({ state, setState, handleDialog, data }) => {
  const { hitAxios } = React.useContext(GlobalContext);
  const [plans, setPlans] = React.useState([]);

  async function getPlans() {
    const res = await hitAxios({
      path: "/api/web/get_plans",
      post: false,
      admin: false,
    });
    if (res.data.success) {
      setPlans(res.data.data);
    }
  }

  React.useEffect(() => {
    getPlans();
  }, []);

  return (
    <div>
      {state.dialogAdd && (
        <Box mt={3} p={2} borderRadius={4} bgcolor="action.hover">
          <Alert severity="info" sx={{ mb: 2 }}>
            <Typography variant="body1" fontWeight={500}>
              {data.paymentWarningTitle}
            </Typography>
            <Typography variant="body2">{data.payementWarning}</Typography>
          </Alert>
          <Stack direction="row" justifyContent="center" spacing={2} mb={3}>
            <Button
              variant="contained"
              color="success"
              startIcon={<CloudSync />}
              onClick={() => window.open(`https://wa.me/918430088300`)}
            >
              {data.contactSupport}
            </Button>
          </Stack>
          <Typography variant="h6" textAlign="center" gutterBottom>
            {data.exportDeployPlan}
          </Typography>
          <PriceComp planArr={plans} data={data} />
        </Box>
      )}
    </div>
  );
};

export default DialogNew;
