import React from "react";
import Header from "./components/header/Header";
import TabsComp from "./TabsComp";
import { TranslateContext } from "../context/TranslateContext";
import { Box, Container } from "@mui/material";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const UserDash = () => {
  const { data } = React.useContext(TranslateContext);
  const history = useHistory();
  let search = window.location.search;
  let params = new URLSearchParams(search);
  let foo = params.get("page");

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    history.push(`?page=${newValue}`);
    // setValue(newValue);
  };

  React.useEffect(() => {
    setValue(parseInt(foo) || 0);
  }, [foo]);

  return (
    <div>
      <Header />
      <Container maxWidth="lg">
        <Box pb={2} mt={2}>
          <TabsComp
            handleChange={handleChange}
            value={value}
            setValue={setValue}
            data={data}
          />
        </Box>
      </Container>
    </div>
  );
};

export default UserDash;
