import { Box, Stack, Typography, Divider, Grid, Button } from "@mui/material";
import React from "react";
import {
  Storage,
  Memory,
  AccessTime,
  ShoppingCartCheckout,
} from "@mui/icons-material";
import { useTheme } from "@emotion/react";
import { loadStripe } from "@stripe/stripe-js";
import { GlobalContext } from "../../../../context/GlobalContext";

const PriceComp = ({ planArr, data }) => {
  const { hitAxios } = React.useContext(GlobalContext);
  const t = useTheme();
  const stripeId = `pk_live_51NGI3WSJ7RHyuQ0AhyqLTkqRfKwt0U6CMHTDB46QqhIC5nkog8B2w63xRmFqWazNerpg3LR8oEqWOLoNZLKD8w5R008xY1rZHq`;

  async function payWithStripe(plan) {
    const stripe = await loadStripe(stripeId);

    const res = await hitAxios({
      path: "/api/user/create_stripe_session",
      post: true,
      admin: false,
      obj: {
        planId: plan?.id,
      },
    });
    if (res.data.success) {
      const result = stripe.redirectToCheckout({
        sessionId: res?.data?.session?.id,
      });

      console.log({ result });
    }
  }

  return (
    <Grid container spacing={3}>
      {planArr.map((plan, key) => (
        <Grid item xs={12} md={4} lg={4} key={key}>
          <Box
            sx={{
              backgroundColor:
                t.palette.mode === "dark" ? "action.hover" : "white",
              borderRadius: 3,
              p: 3,
              transition: "transform 0.3s ease, box-shadow 0.3s ease",
              //   "&:hover": {
              //     transform: "scale(1.03)",
              //     boxShadow: "0 8px 16px rgba(0, 0, 0, 0.1)",
              //   },
            }}
          >
            {/* Title */}
            <Typography
              variant="h6"
              fontWeight={600}
              textAlign="center"
              gutterBottom
            >
              {plan.title}
            </Typography>

            {/* Price and Checkout Button */}
            <Stack
              mt={2}
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              mb={2}
            >
              <Typography variant="h5" color="primary" fontWeight={700}>
                ${plan.price}
              </Typography>
              <Button
                startIcon={<ShoppingCartCheckout />}
                variant="contained"
                color="primary"
                size="small"
                sx={{ textTransform: "none" }}
                onClick={() => payWithStripe(plan)}
              >
                {data.checkOut}
              </Button>
            </Stack>

            <Divider sx={{ mb: 2 }} />

            {/* Features */}
            <Grid container spacing={1}>
              <Grid item xs={4}>
                <Stack alignItems="center">
                  <Storage color="primary" />
                  <Typography variant="subtitle2" fontWeight={500}>
                    {plan.storage}
                  </Typography>
                  <Typography variant="caption" color="text.secondary">
                    {data.storage}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={4}>
                <Stack alignItems="center">
                  <Memory color="primary" />
                  <Typography variant="subtitle2" fontWeight={500}>
                    {plan.ram}
                  </Typography>
                  <Typography variant="caption" color="text.secondary">
                    {data.ram}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={4}>
                <Stack alignItems="center">
                  <AccessTime color="primary" />
                  <Typography variant="subtitle2" fontWeight={500}>
                    {plan.duration}
                  </Typography>
                  <Typography variant="caption" color="text.secondary">
                    {data.duration}
                  </Typography>
                </Stack>
              </Grid>
            </Grid>

            {/* Description */}
            <Typography
              variant="body2"
              color="text.secondary"
              textAlign="center"
              mt={2}
            >
              {plan.description}
            </Typography>
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};

export default PriceComp;
