import React from "react";
import { TranslateContext } from "../context/TranslateContext";
import { GlobalContext } from "../context/GlobalContext";
import {
  CardActionArea,
  Popover,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { ExpandMore, Translate } from "@mui/icons-material";

const ChangeLang = () => {
  const { data } = React.useContext(TranslateContext);
  const { hitAxios } = React.useContext(GlobalContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [lang, setLang] = React.useState([]);

  async function getLangs() {
    const res = await hitAxios({
      path: "/api/web/get-all-translation-name",
      admin: false,
      post: false,
    });
    if (res.data.success) {
      setLang(res.data.data);
    }
  }

  React.useEffect(() => {
    getLangs();
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div>
      <Stack
        onClick={handleClick}
        id="translate"
        sx={{ cursor: "pointer" }}
        alignItems={"center"}
        direction={"row"}
      >
        <Tooltip title={data.changeLang}>
          <Translate sx={{ color: "#01e784", height: 35, width: 35 }} />
        </Tooltip>
        <ExpandMore sx={{ color: "white", height: 18, width: 18 }} />
      </Stack>

      <Popover
        id="translate"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Stack direction={"column"}>
          {lang?.map((i, key) => {
            return (
              <CardActionArea
                onClick={() => {
                  localStorage.setItem("language", i.replace(".json", ""));
                  window.location.reload();
                }}
                key={key}
                sx={{ p: 1.5 }}
              >
                <Typography>{i?.replace(".json", "")}</Typography>
              </CardActionArea>
            );
          })}
        </Stack>
      </Popover>
    </div>
  );
};

export default ChangeLang;
