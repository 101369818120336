import { BuildCircle, Close, Domain, RocketLaunch } from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  Dialog,
  Divider,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import DialogBox from "../../../../../components/DialogBox";
import TextFieldNew from "../../../../../components/TextFieldNew";
import { GlobalContext } from "../../../../../context/GlobalContext";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const SetupApp = ({ i, data, contextData }) => {
  const history = useHistory();
  const { hitAxios } = React.useContext(GlobalContext);
  const [state, setState] = React.useState({
    dialog: false,
    domain: "",
  });

  async function deployApp(params) {
    const res = await hitAxios({
      path: "/api/deploy/run",
      post: true,
      admin: false,
      obj: { ...state, purchaseId: i?.purchase_id },
    });
    if (res.data.addApp) {
      history.push(`/user?page=0`);
    }
  }

  return (
    <div>
      <Button
        disabled={i.domain && i?.app_name && i?.purchase_code ? true : false}
        onClick={() => setState({ ...state, dialog: true })}
        startIcon={<RocketLaunch />}
        variant="contained"
        color="primary"
      >
        {data.deploy}
      </Button>

      <DialogBox
        icon={<BuildCircle />}
        open={state.dialog}
        title={data.setupApp}
        handleDialog={() => setState({ ...state, dialog: !state.dialog })}
      >
        <Stack direction={"column"} spacing={2}>
          <TextFieldNew
            value={state.purchaseCode}
            onChange={(e) =>
              setState({ ...state, purchaseCode: e.target.value })
            }
            label={data.purchaseCode}
            helperText={data.purchaseCodeHelper}
          />
          <TextFieldNew
            startIcon={<Typography color={"gray"}>https://</Typography>}
            value={state.domain}
            onChange={(e) => setState({ ...state, domain: e.target.value })}
            label={data.domain}
          />
          <Button
            onClick={deployApp}
            startIcon={<RocketLaunch />}
            variant="contained"
            color="primary"
          >
            {data.deploy}
          </Button>

          {contextData?.snack_msg && contextData?.snack_msg !== "Great" && (
            <Alert
              sx={{ borderRadius: 3 }}
              severity={contextData?.snack_success ? "success" : "error"}
            >
              <Typography>{contextData?.snack_msg}</Typography>
            </Alert>
          )}
        </Stack>
      </DialogBox>
    </div>
  );
};

export default SetupApp;
