import React from "react";
import { TranslateContext } from "../../../context/TranslateContext";
import { GlobalContext } from "../../../context/GlobalContext";
import { Alert, Button, Stack, Typography } from "@mui/material";
import { AddCircle, Cancel, CloudSync } from "@mui/icons-material";
import DialogNew from "./components/DialogNew";
import MyApps from "./components/MyApps";

const AutoDeploy = () => {
  const [state, setState] = React.useState({
    dialogAdd: false,
  });
  const { data } = React.useContext(TranslateContext);
  const { hitAxios } = React.useContext(GlobalContext);
  return (
    <div>
      <Stack direction={"column"} spacing={3}>
        <Alert
          icon={<CloudSync />}
          sx={{
            borderRadius: 4,
          }}
          severity="info"
        >
          <Typography>{data.autoInstallDes}</Typography>
        </Alert>

        <Stack direction={"row"} justifyContent={"flex-end"}>
          <Button
            onClick={() => setState({ ...state, dialogAdd: !state.dialogAdd })}
            startIcon={state.dialogAdd ? <Cancel /> : <AddCircle />}
            variant="contained"
            sx={{
              bgcolor: "black",
            }}
          >
            {data.deployNewApp}
          </Button>
        </Stack>
      </Stack>

      <DialogNew
        data={data}
        handleDialog={() => setState({ ...state, dialogAdd: !state.dialogAdd })}
        state={state}
        setState={setState}
      />

      <MyApps state={state} setState={setState} data={data} />
    </div>
  );
};

export default AutoDeploy;
